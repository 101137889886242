
import { Component } from 'vue-property-decorator';
import { mixins } from 'vue-class-component';
import Editable from '@/mixins/Editable';
import Notification from '@/mixins/Notification';
import StackedForm from '@/mixins/StackedForm';
import VWrapper from '@/components/shared/VWrapper.vue';
import { namespace } from 'vuex-class';
import EditUiComponentLoader from '@/components/ui/EditUiComponentLoader.vue';
import UiComponentForm from '@/components/ui/UiComponentForm.vue';
import { UiComponent as UiComponentModel } from '@/interfaces/models/UiComponent';
import { scrollToFailedField } from '@/util/helper';

const uiComponent = namespace('uiComponent');

@Component({
  components: { VWrapper, UiComponentForm, EditUiComponentLoader },
})
export default class EditUiBanner extends mixins(Editable, Notification, StackedForm) {
  @uiComponent.Action('store') public store!: any;
  @uiComponent.Action('show') public show!: any;
  @uiComponent.Action('update') public update!: any;
  @uiComponent.State('active') public active!: UiComponentModel;

  public $refs!: {
    form: InstanceType<typeof UiComponentForm> & { getData: () => any; validate: () => Promise<boolean | boolean[]> };
  };

  get uiComponent() {
    if (this.editing && this.active) {
      return this.active;
    }

    return null;
  }

  get title() {
    return this.editing ? 'ui.banner.edit' : 'ui.banner.create';
  }

  public save() {
    this.$refs.form.validate().then(async (res: boolean | boolean[]) => {
      this.$startLoading('ui.banner.saving');
      if (this.isValid(res)) {
        if (this.editing) {
          await this.update({ type: 'banner', data: this.$refs.form.getData(), id: this.uiComponent!._id });
        } else {
          await this.store({ type: 'banner', data: this.$refs.form.getData() });
        }

        this.$router.push({ name: 'ui.banner.index' });
      } else {
        scrollToFailedField();

        this.notifyError('notification.form');
      }
      this.$stopLoading('ui.banner.saving');
    });
  }

  public async mounted() {
    if (this.editing) {
      this.$startLoading('ui.banner');
      await this.show({ type: 'banner', id: this.id });
    }

    if (this.editing && !this.active) {
      this.$router.push({ name: 'ui.banner.index' });
    }
    this.$stopLoading('ui.banner');
  }
}
